// Plugins

// FontAwesome 5
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

// Header Styles
.demo, .logged-in-as {
  margin: 0 !important;
  border-radius: 0 !important;
}

.noresults-div{
    height:180px;
    background-color: #00afff05;
    display:flex;
    align-items:center;
    border:solid #0e3c66;
    .notfound{
        color:#0e3c66;
        font-size:20px;
        padding:20px;
    }
}

